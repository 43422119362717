import { default as indexPgJ1T46SAPMeta } from "C:/work/server-store/frontend/pages/index.vue?macro=true";
export default [
  {
    name: indexPgJ1T46SAPMeta?.name ?? "index",
    path: indexPgJ1T46SAPMeta?.path ?? "/",
    meta: indexPgJ1T46SAPMeta || {},
    alias: indexPgJ1T46SAPMeta?.alias || [],
    redirect: indexPgJ1T46SAPMeta?.redirect || undefined,
    component: () => import("C:/work/server-store/frontend/pages/index.vue").then(m => m.default || m)
  }
]
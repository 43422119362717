<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
.page-enter-active,
.page-leave-active {
    transition: all .5s;
}

.page-enter-from,
.page-leave-to {
    transition: all .5s;
    opacity: 0;
}
</style>

